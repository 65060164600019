import { apiClient } from "@netgame/openapi";
import dayjs from "dayjs";

interface IUpdateCashback {
	status: string;
	availableAfterDateTime: string | null;
	availableAfterDay: string;
}

export const STATUS_FORM_LINK =
	"https://docs.google.com/forms/d/e/1FAIpQLSdmr9qzxrZH5esIwtQ68Izdu7qamYTLOKe-0_LBbhr-Yq8Snw/viewform";

const useStatus = () => {
	const { open } = useNlcModals();
	const { refresh: refreshHomePage } = useHomeData({ immediate: false });
	const { promotionsData, refresh: refreshPromo } = usePromotionsData({ server: true, immediate: false });

	const weeklyCashbackData = computed(
		() => promotionsData.value?.promotions.find((item) => item?.type === "rankLeagueCashback")?.data
	);

	const weeklyCashbackTime = computed(() => weeklyCashbackData.value?.availableAfterDateTime);

	const { durationLeft, duration, reset } = useCountdown({
		timestamp: dayjs(weeklyCashbackTime.value),
		formatToken: "D[D ]H[H ]mm[M ]"
	});

	const unwatchTimer = watch(
		() => weeklyCashbackData.value?.availableAfterDateTime,
		(value) => {
			if (value) {
				reset(value);
			}
		}
	);

	const resetWeeklyCashbackTimer = () => {
		if (weeklyCashbackData.value?.availableAfterDateTime) {
			reset(weeklyCashbackData.value?.availableAfterDateTime);
		}
	};

	const durationLeftDisplay = computed(() => (duration.value > 0 ? durationLeft.value : "0D 0H 0M"));

	const hasWeeklyReward = computed(
		() => !!weeklyCashbackData.value?.currentReward && weeklyCashbackData.value?.currentReward > 0
	);

	const nextSectionTitle = computed(
		() =>
			weeklyCashbackData.value?.sections?.find((item) => item?.weeklyReward === weeklyCashbackData.value?.nextReward)
				?.title
	);

	const claimIsActive = computed(() => weeklyCashbackData?.value?.status === "available");

	const updateCashback = ({ status, availableAfterDateTime, availableAfterDay }: IUpdateCashback) => {
		const cashbackPromo = promotionsData.value?.promotions.find((item) => item?.type === "rankLeagueCashback");
		if (!cashbackPromo) {
			return;
		}

		if (weeklyCashbackData && weeklyCashbackData.value) {
			const oldTimer = weeklyCashbackData.value.availableAfterDateTime;

			weeklyCashbackData.value.availableAfterDay = availableAfterDay;
			weeklyCashbackData.value.status = status;
			weeklyCashbackData.value.availableAfterDateTime = availableAfterDateTime || weeklyCashbackTime.value;

			if (!!availableAfterDateTime && availableAfterDateTime !== oldTimer) {
				resetWeeklyCashbackTimer();
			}
		}
	};

	const claimWeeklyReward = async () => {
		try {
			await apiClient({
				path: "/rest/cashback/pay",
				method: "post",
				options: {
					onResponse: ({ response }) => {
						const prize = response?._data?.data;

						if (prize) {
							open("LazyOModalVipClubClaimReward", {
								prize,
								nextSectionTitle: nextSectionTitle.value || ""
							});
						}
					}
				}
			});

			await refreshPromo();
			await refreshHomePage();
		} catch (e) {
			console.error("Error :>> ", e);
		}
	};

	onUnmounted(() => {
		unwatchTimer();
	});

	return {
		weeklyCashbackData,
		durationLeft,
		hasWeeklyReward,
		claimIsActive,
		weeklyCashbackTime,
		duration,
		durationLeftDisplay,
		claimWeeklyReward,
		updateCashback,
		resetWeeklyCashbackTimer
	};
};

export default useStatus;
