import useStatus from "./useStatus";

type SocketStatusData = {
	currentSection: {
		id: number;
		title: string;
	};
	currentLevel: {
		id: number;
		title: string;
		index?: number;
		currentExperience: number;
		neededExperience: number;
	};
	nextSectionBenefits?: string[];
	weeklyReward?: number;
	nextLevel: {
		id: number;
		title: string;
	};
	progressBlockedReason: null | string;
	progressData: {
		currentSection: {
			id: number;
			title: string;
		};
		currentLevel: {
			id: number;
			title: string;
			currentExperience: number;
			neededExperience: number;
		};
	};
	status: "available" | "waiting";
	availableAfterDateTime: null | string;
	availableAfterDay: string;
	statusCalculation: boolean;
};

const useStatusLeagueSockets = () => {
	const { open } = useNlcModals();
	const { data: appInitData } = useAppInitData();
	const { refresh: refreshHomePage } = useHomePage();
	const { refresh: refreshPromotionsData } = usePromotionsData({ immediate: false });
	const { add } = useSockets();
	const { updateCashback } = useStatus();
	const isCashbackCalculation = useState("isCashbackCalculation", () => false);

	const statusUp = (data: SocketStatusData) => {
		const { rankLeague } = appInitData.value || {};
		if (!rankLeague) {
			return;
		}

		open("LazyOModalVipClubStatusUp", {
			section: data?.currentSection,
			prize: rankLeague?.currentLevel?.prize,
			benefits: rankLeague?.nextSectionBenefits,
			weeklyReward: rankLeague?.weeklyReward,
			progressBlockedReason: !!data?.progressBlockedReason
		});
		rankLeague.currentLevel = data?.currentLevel;
		rankLeague.currentSection = data?.currentSection;
		rankLeague.nextLevel = data?.nextLevel;
		rankLeague.nextSectionBenefits = [];
		rankLeague.weeklyReward = undefined;
		refreshHomePage();
		refreshPromotionsData();
	};

	add("rankLeague", ({ action, data }: EventData<SocketStatusData>) => {
		const { rankLeague } = appInitData.value || {};

		if (!rankLeague?.enabled) {
			return;
		}

		if (action === "progressData") {
			if (
				data?.progressData?.currentLevel?.currentExperience &&
				(rankLeague?.currentLevel?.currentExperience || rankLeague?.currentLevel?.currentExperience === 0)
			) {
				rankLeague.currentLevel.currentExperience = data?.progressData?.currentLevel?.currentExperience;
			}
		}
		if (action === "levelUp") {
			rankLeague.progressBlockedReason = data?.progressBlockedReason;

			if (data?.currentSection && data?.currentLevel) {
				if (data?.currentLevel?.index === 1) {
					return;
				}

				rankLeague.nextSectionBenefits = data.nextSectionBenefits || [];
				rankLeague.weeklyReward = data.weeklyReward || undefined;

				open("LazyOModalVipClubLevelUp", {
					currentStatus: data?.currentSection?.id,
					currentLevel: data?.currentLevel?.index,
					prize: rankLeague?.currentLevel?.prize,
					levelTitle: data?.currentLevel?.title
				});
				rankLeague.currentLevel = data?.currentLevel;
				rankLeague.currentSection = data?.currentSection;
				rankLeague.nextLevel = data?.nextLevel;
			}
		}

		if (action === "sectionUp") {
			rankLeague.progressBlockedReason = data?.progressBlockedReason;
			statusUp(data);
		}

		if (action === "rankLeagueCashbackCalculated" || action === "rankLeagueCashbackExpired") {
			updateCashback({
				status: data?.status,
				availableAfterDateTime: data?.availableAfterDateTime,
				availableAfterDay: data?.availableAfterDay
			});
		}
	});

	add("cashback", ({ action, data }: EventData<SocketStatusData>) => {
		if (action === "rankLeagueCashbackCalculationStatus") {
			isCashbackCalculation.value = data?.statusCalculation || false;
		}
	});
};

export default useStatusLeagueSockets;
