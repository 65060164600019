import { apiClient } from "@netgame/openapi";
import { defu } from "defu";

import type { NextTournamentsResponse, PrevTournamentsResponse } from "@/types";

type TournamentsData = {
	"/rest/next-tournaments/": Record<string, NextTournamentsResponse>;
	"/rest/tournaments-history/": Record<string, PrevTournamentsResponse>;
};

const dataKey = "tournaments";

const useAppGetTournamentsData = (immediate = true) => {
	const offset = ref(0);
	const activePath = ref<keyof TournamentsData>("/rest/next-tournaments/");

	const headers = useRequestHeaders();

	return {
		offset,
		activePath,
		...useAsyncData<TournamentsData>(
			dataKey,
			async (nuxtApp) => {
				const prevData = nuxtApp!.payload?.data?.[dataKey];
				try {
					const nextResult = await apiClient({
						path: "/rest/next-tournaments/",
						method: "get",
						options: {
							query: { limit: 5, offset: offset.value },
							headers
						}
					});
					const prevResult = await apiClient({
						path: "/rest/tournaments-history/",
						method: "get",
						options: {
							query: { limit: 4, offset: offset.value },
							headers
						}
					});
					return defu(prevData, {
						"/rest/next-tournaments/": {
							[`${5}_${offset.value}`]: nextResult
						},
						"/rest/tournaments-history/": {
							[`${4}_${offset.value}`]: prevResult
						}
					});
				} catch (err) {
					console.error(err);
					return prevData;
				}
			},
			{
				immediate,
				watch: [offset]
			}
		)
	};
};

export default useAppGetTournamentsData;
