const BANNER_PRESET_PACKAGE_TIMER = 3600000;

const usePresetPackageController = () => {
	const { data: appInit } = useAppInitData();
	const route = useRoute();
	const { open: openPopup, close: closePopup } = useNlcModals();
	const guardFunction = usePopupPresetPackageGuard();

	const cookie = useStatefulCookie<string | null>("presetPackageModal", {
		expires: new Date(Date.now() + 1 * 60 * 60 * 1000),
		path: "/"
	});

	const timer = useState<ReturnType<typeof setTimeout> | number | null>("timer", () => null);

	const openDefault = () => {
		guardFunction({
			success: () => {
				openPopup("LazyOModalPresetPackage");
			}
		});
	};

	const close = () => {
		guardFunction({
			success: () => {
				closePopup("LazyOModalPresetPackage");
			}
		});
	};

	const closeAfterTimer = () => {
		guardFunction({
			success: () => {
				if (appInit.value?.popupPresetPackage) {
					appInit.value.popupPresetPackage = undefined;
				}
				closePopup("LazyOModalPresetPackage");
			}
		});
	};

	const openAfterAuth = () => {
		guardFunction({
			success: () => {
				setTimeout(() => {
					dispatchGAEvent({
						event: "open_popup",
						location: "login",
						form_name: "bonus_offer",
						quantity: appInit.value?.depositsCount || 0
					});
				}, 1500);
				openPopup("LazyOModalPresetPackage");
			}
		});
	};

	const openInTime = () => {
		guardFunction({
			success: () => {
				if (!BANNER_PRESET_PACKAGE_TIMER) {
					return;
				}
				if (route?.path?.includes("game") || route?.query?.game || route?.hash?.includes("cash")) {
					return;
				}
				if (timer.value) {
					clearTimeout(timer.value);
				}
				timer.value = setTimeout(() => {
					setTimeout(() => {
						dispatchGAEvent({
							event: "open_popup",
							location: "reminder",
							form_name: "bonus_offer",
							quantity: appInit.value?.depositsCount || 0
						});
					}, 1500);
					cookie.value = "onReload";
					openPopup("LazyOModalPresetPackage");
				}, BANNER_PRESET_PACKAGE_TIMER);
			}
		});
	};

	return {
		openDefault,
		openAfterAuth,
		openInTime,
		close,
		closeAfterTimer
	};
};

export default usePresetPackageController;
