<script setup lang="ts">
useHead({
	title: "Page not found - NoLimitCoins Social Casino: Play for Free"
});

const { t } = useT();
const handleError = () => clearError({ redirect: "/" });
</script>
<template>
	<div>
		<NuxtLayout>
			<div class="error">
				<NuxtImg class="error-img" src="/nuxt-img/error/error.png" alt="404" format="webp" width="424" height="405" />
				<div class="content">
					<AText variant="tirana" :modifiers="['bold', 'center']" as="h1">{{ t("Page not found") }}</AText>
					<AText class="info-text" :modifiers="['center']">{{
						t("Welcome and thank you for visiting our free-to-play")
					}}</AText>
					<AButton variant="outline" class="back-home" size="lg" data-tid="error-return-home" @click="handleError">
						<AText :modifiers="['bold', 'uppercase']">{{ t("Return home") }}</AText>
					</AButton>
				</div>
			</div>
		</NuxtLayout>
		<ASvgCollection />
		<ClientOnly>
			<SupportChat />
			<OModals />
		</ClientOnly>
	</div>
</template>
<style lang="scss" scoped>
.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 gutter(4) gutter(15);
}

.content {
	display: flex;
	margin-top: gutter(-11.25);
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(sm) {
		margin-top: gutter(-6.25);
	}
}

.error-img {
	max-width: 100%;
	display: block;
	height: auto;
	object-fit: cover;

	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 264px;
	}
}

.back-home {
	width: 100%;
	max-width: 296px;

	@include media-breakpoint-down(md) {
		max-width: 353px;
	}
}

.info-text {
	margin: gutter(2) 0;
}
</style>
