export default defineNuxtRouteMiddleware(async (to) => {
	const { protocol, host } = useRequestURL();
	const url = to?.fullPath;
	const searchParams = new URLSearchParams(url.split("?")[1]);

	const appSchemaName = searchParams.get("app_schema_name");

	if (appSchemaName) {
		searchParams.delete("app_schema_name");

		const path = url.split("?")[0];
		const params = searchParams?.toString();
		const redirectUrl = path + (params ? `?${params}` : "");
		const webLink = host + redirectUrl;
		const deepLink = `${appSchemaName}:/${redirectUrl}`;
		const symbol = webLink.includes("?") ? "&" : "?";

		await navigateTo(`${protocol}//${webLink}${symbol}deepLink=${deepLink}`, {
			external: true
		});
	}
});
