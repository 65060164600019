import dayjs from "dayjs";

import type { Promotion } from "@/types";

const hasPromotionInfoMap = {
	invite: true,
	dailyWheel: true,
	verification: true,
	inviteQualification: true,
	emailLottery: true,
	tournament: true,
	promoOfferConstructor: true
} as const;

const getSecretOffer = (promotion: Promotion) =>
	["offerDeposit", "offerBirthday"].includes(promotion.type || "") &&
	promotion?.data?.promoOfferPreset?.forCompleteProfile;

const selectPrizeUsualPrice = (promotion: Promotion) => {
	if (!promotion.data?.promoOfferPreset?.usualPrice) {
		return;
	}
	return Number(promotion.data?.promoOfferPreset?.usualPrice).toFixed(2);
};

const selectBadgeLabel = (promotion: Promotion) => parseJSON(promotion.data?.promoOfferPreset?.badgeLabel || "{}");

const selectPackage = ({
	entries,
	coins,
	howWorks,
	promotion,
	isSweepStakes,
	isProfileCompleted,
	t
}: {
	entries: number;
	coins: number;
	howWorks: string;
	promotion: Promotion;
	isSweepStakes: boolean;
	isProfileCompleted: boolean;
	t: ReturnType<typeof useT>["t"];
}) => {
	const packages = {
		dailyWheel: {
			title: t("Lucky Wheel"),
			entries: 0,
			coins: 0,
			type: "dailyWheel",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: t("Spin the Lucky Wheel once a day to collect your free GC & SC"),
			howWorks: ""
		},
		inviteQualification: {
			title: "",
			entries,
			coins,
			type: "inviteQualification",
			imagePopup: "/nuxt-img/promotions/invite-qualification2x.jpg",
			imagePopupMobile: "",
			subTitle: "Get the reward as a qualified friend",
			howWorks: ""
		},
		partnerReferralQualification: {
			title: "",
			entries,
			coins,
			type: "partnerReferralQualification",
			imagePopup: "/nuxt-img/promotions/affiliate-bonuses.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		emailLottery: {
			title: t("Email Prize Draw"),
			entries: 0,
			coins: 0,
			type: "emailLottery",
			imagePopup: "/nuxt-img/promotions/email-lottery.jpg",
			imagePopupMobile: "",
			subTitle: t("Reply to our special emails to get a chance to win GC 25,000"),
			howWorks: ""
		},
		verification: {
			title: "",
			entries: 0,
			coins: 0,
			type: "verification",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		invite: {
			title: t("Invite Friend"),
			entries,
			coins,
			type: "invite",
			imagePopup: "/nuxt-img/promotions/invite-friend.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks
		},
		tournament: {
			title: "",
			entries: 0,
			coins: 0,
			type: "tournament",
			imagePopup: `nuxt-img/promotions/tournament-${isSweepStakes ? "entries" : "coins"}.jpg`,
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		promoOfferConstructor: {
			title: "",
			type: "promoOfferConstructor",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			footTxt: "",
			coins: 0,
			entries: 0,
			howWorks: ""
		},
		offerDeposit: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		offerPeriodic: {
			title: "",
			entries: 0,
			coins: 0,
			type: "offerDeposit",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		rankLeague: {
			title: t("VIP Club"),
			entries: 0,
			coins: 0,
			type: "rankLeague",
			imagePopup: "/nuxt-img/promotions/vip-club.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		rankLeagueCashback: {
			title: t("Weekly Reward"),
			entries: 0,
			coins: 0,
			type: "rankLeagueCashback",
			imagePopup: "/nuxt-img/promotions/weekly-reward2x.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		rankLeagueBirthdayGift: {
			title: t("Happy birthday!"),
			entries: 0,
			coins: 0,
			type: "rankLeagueBirthdayGift",
			imagePopup: "",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		depositStreak: {
			title: t("Wheel of Wins"),
			entries: 0,
			coins: 0,
			type: "depositStreak",
			imagePopup: "/nuxt-img/promotions/deposit-streak.jpg",
			imagePopupMobile: "",
			subTitle: t("Luck Strikes Every 8th Time!"),
			howWorks: ""
		},
		season: {
			title: "",
			entries: 0,
			coins: 0,
			type: "season",
			imagePopup: "/nuxt-img/seasons/halloween/promotions-desktop.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		},
		bingo: {
			title: "",
			entries: 0,
			coins: 0,
			type: "bingo",
			imagePopup: "/nuxt-img/promotions/bingo2x.jpg",
			imagePopupMobile: "",
			subTitle: "",
			howWorks: ""
		}
	};
	if (getSecretOffer(promotion) && !isProfileCompleted) {
		return {
			...promotion.data?.promoOfferPreset,
			title: t("Special Personal Offer"),
			subTitle: "",
			imagePopup: "/nuxt-img/promotions/secret-offer-new.jpg",
			imagePopupMobile: "",
			coins: 0,
			howWorks: ""
		};
	}
	if (promotion.data?.promoOfferPreset) {
		return promotion.data?.promoOfferPreset;
	}
	const packageType = (promotion.type || "promoOfferConstructor") as keyof typeof packages;
	if (!(packageType in packages)) {
		return;
	}
	return packages[packageType];
};

const selectTooltipText = ({ t, promotion }: { t: ReturnType<typeof useT>["t"]; promotion: Promotion }) => {
	const daysOfWeek = promotion.data?.promoOfferPreset?.dayOfWeek || [];
	const hasOneDay = daysOfWeek.length === 1;
	if (promotion.data?.needDeposits) {
		if (promotion.data?.needDeposits === 1) {
			return t("Available after the st purchase", { msg: promotion.data?.needDeposits });
		}
		return t("Available after the nd purchase", { msg: promotion.data?.needDeposits });
	}
	if (promotion.data?.promoOfferPreset?.isLimitPerDayReason) {
		if (!hasOneDay) {
			return t("Can be claimed again next weekends.");
		}
		const index = parseInt(daysOfWeek[0]);
		return t("Available after the", { days: dayjs().day(index).format("dddd") });
	}
	if (promotion.data?.promoOfferPreset?.isMinBalanceReason) {
		return t("Can be claimed again when your total entries + winnings + pending redeems are less than.", {
			msg: promotion.data?.promoOfferPreset.minBalance
		});
	}
	if (hasOneDay) {
		const index = parseInt(daysOfWeek[0]);
		return t("Available on", { days: dayjs().day(index).format("dddd") });
	}
	return t("Available on weekends");
};

const selectHowWorks = (item: ReturnType<typeof selectPackage>) => {
	if (item === undefined) {
		return item;
	}
	return {
		...item,
		howWorks: parseJSON<Record<string, string | number>>(item?.howWorks || "{}")
	};
};

const selectHasPromotionInfo = (promotion: Promotion) => {
	const type = promotion.type || "";
	return type in hasPromotionInfoMap;
};

const selectDate = (promotion: Promotion) =>
	dayjs().add(
		promotion.data?.promoOfferPreset?.availableTill ? Number(promotion.data?.promoOfferPreset?.availableTill) : 0,
		"second"
	);

export const createDecorator =
	(t: ReturnType<typeof useT>["t"]) => (promotion: Promotion, isSweepStakes: boolean, isProfileCompleted: boolean) => {
		const useualPrice = selectPrizeUsualPrice(toRaw(promotion));
		const badgeLabel = selectBadgeLabel(toRaw(promotion));
		const secretOffer = getSecretOffer(toRaw(promotion)) && !isProfileCompleted;

		const pkg = selectHowWorks(
			selectPackage({
				t,
				entries: promotion.data?.prizeEntries || promotion.data?.bonusEntries || 0,
				coins: promotion.data?.prizeCoins || promotion.data?.bonusCoins || 0,
				howWorks: promotion.data?.howWorks || "{}",
				promotion,
				isSweepStakes,
				isProfileCompleted
			})
		);
		const hasPromotionInfo = selectHasPromotionInfo(promotion);
		const date = selectDate(promotion);
		const tooltipText = selectTooltipText({ t, promotion });
		return {
			...promotion,
			package: pkg,
			useualPrice,
			hasPromotionInfo,
			date,
			tooltipText,
			badgeLabel,
			secretOffer
		};
	};
