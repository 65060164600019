import type { PromoResp, Promotion } from "@/types";

const normalize = (
	promotions: Array<Promotion>,
	isSweepStakes: boolean,
	isProfileCompleted: boolean,
	decorator: ReturnType<typeof createDecorator>,
	condition: (promotion: Promotion) => boolean
) => {
	if (!promotions) {
		return [];
	}

	return promotions.reduce((accumulator: Array<Promotion>, promotion) => {
		if (condition(promotion)) {
			return accumulator;
		}
		return [...accumulator, decorator(promotion, isSweepStakes, isProfileCompleted)];
	}, []) as Array<ReturnType<typeof decorator>>;
};

const transform =
	(decorator: ReturnType<typeof createDecorator>) => (data: PromoResp, mode: boolean, profileCompleted: boolean) => {
		const promotions = normalize(
			data?.payload?.promotions as Array<Promotion>,
			mode,
			profileCompleted,
			decorator,
			(_) => false
		);

		const availableSoon = normalize(
			data?.payload?.available_soon as Array<Promotion>,
			mode,
			profileCompleted,
			decorator,
			(_) => false
		);
		return {
			promotions,
			availableSoon
		};
	};

const defaultData = (): ReturnType<ReturnType<typeof transform>> => ({
	promotions: Array(4).fill({
		skeleton: true
	}),
	availableSoon: Array(4).fill({
		skeleton: true
	})
});

const usePromotionsData = ({ immediate = true, server = false }: { immediate?: boolean; server?: boolean } = {}) => {
	const { t } = useT();
	const { isSweepStakes } = useSwitchMode();
	const { data: appInit } = useAppInitData();

	const isProfileCompleted = computed(() => {
		const { name, surname, birthDay, profileCompleted } = appInit?.value || {};
		return profileCompleted || !!(name && surname && birthDay);
	});

	const { data: promoData, refresh } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			immediate,
			server
		}
	});

	const promotionsData = computed(() => {
		if (!promoData.value) {
			return defaultData();
		}

		return transform(createDecorator(t))(promoData.value, isSweepStakes.value, isProfileCompleted.value);
	});

	return {
		refresh,
		promotionsData
	};
};

export default usePromotionsData;
