const useCheckPWA = () => {
	const isPWA = ref(false);

	const handleChangePWA = (event: MediaQueryListEvent) => {
		isPWA.value = event.matches;
	};

	const checkPWA = () => {
		const mediaQueryList = window.matchMedia("(display-mode: standalone)");
		isPWA.value = mediaQueryList.matches;
		if (mediaQueryList) {
			mediaQueryList?.addEventListener("change", handleChangePWA);
		}
	};

	onMounted(() => {
		checkPWA();
	});

	onBeforeUnmount(() => {
		const mediaQueryList = window.matchMedia("(display-mode: standalone)");
		if (mediaQueryList) {
			mediaQueryList?.removeEventListener("change", handleChangePWA);
		}
	});

	return {
		isPWA
	};
};

export default useCheckPWA;
