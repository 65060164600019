import useAsyncFetch from "./useAsyncFetch";
import useCountdown from "./useCountdown";

const useDepositStreakData = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const { data: depositInfoData, refresh } = useAsyncFetch({
		path: "/rest/deposit-streak/info/",
		method: "get",
		options: {
			immediate,
			default: () => ({
				maxNumberOfDeposit: 8,
				currentCountDeposit: 0,
				finishedAt: "0000-00-00 00:00:00",
				expiredAt: "0000-00-00 00:00:00"
			}),
			transform: (data) => {
				const canWheel = data.success && data.currentCountDeposit === data.maxNumberOfDeposit;
				return {
					...data,
					canWheel
				};
			}
		}
	});

	const isActiveMiniWheel = computed(
		() =>
			depositInfoData.value.success &&
			depositInfoData.value.canWheel &&
			!depositInfoData.value.secondsToResetStreakCounter
	);
	const isPercentEnabled = computed(() => depositInfoData.value.percentEnabled);
	const secondsToResetStreakCounter = computed(() => secondsToDate(depositInfoData.value.secondsToResetStreakCounter));

	const { durationLeft, reset } = useCountdown({
		timestamp: depositInfoData.value.finishedAt as string,
		formatToken: "DD[D ] HH[H ]mm[M ]",
		isOptimized: true,
		onCountdownStop: immediate ? refresh : () => {}
	});

	const { durationLeft: timerWheelDisabled, reset: resetWheelDisabled } = useCountdown({
		timestamp: secondsToResetStreakCounter.value,
		formatToken: "HH:mm:ss",
		isOptimized: true,
		onCountdownStop: immediate ? refresh : () => {}
	});

	watch(
		() => depositInfoData.value.success,
		() => reset(depositInfoData.value.finishedAt as string)
	);

	watch(
		() => depositInfoData.value.secondsToResetStreakCounter !== null,
		() => resetWheelDisabled(secondsToResetStreakCounter.value)
	);

	// NLC
	const isActiveSkinWheelOfWins = "FallSeason"; // "FallSeason" || null
	const locizeWheelOfWins = computed(() => `WheelOfWins${isActiveSkinWheelOfWins ? ".skin" : ""}`);
	const imgWheelOfWins = computed(() => `/nuxt-img/wheel-of-wins${isActiveSkinWheelOfWins ? "/skin" : ""}`);
	// end NLC

	return {
		depositInfoData,
		refresh,
		durationLeft,
		isActiveMiniWheel,
		isPercentEnabled,
		timerWheelDisabled,
		isActiveSkinWheelOfWins,
		locizeWheelOfWins,
		imgWheelOfWins
	};
};

export default useDepositStreakData;
