import type { SocketResponseTournament } from "@/types";

const useTournamentSockets = () => {
	const { data: tournamentData, refresh } = useGetTournamentData();
	const { data: tournamentsData, activePath, refresh: refreshTournament } = useAppGetTournamentsData(false);
	const { add } = useSockets();

	add("tournament", (response: SocketResponseTournament) => {
		const responseHistoryId = tournamentData.value?.data?.historyId;
		if (responseHistoryId === null && response.action === "start") {
			refresh();
		}
		if (response.historyId && responseHistoryId !== response.historyId) {
			return;
		}
		if (response.action === "start" || response.action === "stop") {
			if (tournamentsData.value?.[activePath.value]) {
				refreshTournament();
			}
			if (tournamentData.value?.data) {
				tournamentData.value.data.winners = response.winners;
			}
		}
		if (response.action === "positions") {
			if (tournamentData.value?.data) {
				tournamentData.value.data.winners = response.winners;
			}
			if (response.playerData && tournamentData.value?.playerData) {
				tournamentData.value.playerData = response.playerData;
			}
			return;
		}
		if (response.action === "bet") {
			if (tournamentData.value?.playerData) {
				tournamentData.value.playerData.roundsPlayed = response.bets_count;
			}
			return;
		}
		if (response.action === "playerWin") {
			if (tournamentData.value?.playerData) {
				tournamentData.value.playerData.points = response.data.totalPoints;
				tournamentData.value.playerData.place = response.data.place;
			}
		}
	});
};

export default useTournamentSockets;
