import type { EventData } from "@/types";

type SocketSeasonData = {
	completedLevelIndex?: number;
	levelProgressPercents?: number;
	entries?: number;
	coins?: number;
	currentLevel?: number;
};

const useSeasonsSocket = () => {
	const { add, remove } = useSockets();
	const { open } = useNlcModals();
	const { exit } = useFullscreen();
	const { data: appInitData } = useAppInitData();

	add("season", ({ action, data }: EventData<SocketSeasonData>) => {
		const { season } = appInitData.value || {};

		if (action === "progressUpdate" && season?.totalProgressPercents !== undefined) {
			season.totalProgressPercents = data?.levelProgressPercents ?? 0;
			season.currentLevel = data?.currentLevel ?? 1;
		}

		if (action === "prizeAssigned" && data?.entries) {
			exit().catch((err) => console.error(`[${err}]: Error when trying to exit full screen mode`));

			open("LazyOModalSpookySeasonCongratulations", {
				detail: { prize: data.entries * 100, completedLevelIndex: season?.currentLevel || 1 + 1 }
			});
		}
	});

	onUnmounted(() => {
		remove("season");
	});
};

export default useSeasonsSocket;
