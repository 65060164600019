const useDeepLink = () => {
	const router = useRouter();
	const route = useRoute();

	const checkDeepLink = () => {
		if (route.query?.deepLink) {
			const deepLink = `${route.query.deepLink}`;

			window.location.href = deepLink;
			router.replace({ query: {} });
		}
	};

	return {
		checkDeepLink
	};
};

export default useDeepLink;
