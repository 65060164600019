const useInitRegisterComplete = () => {
	const { open } = useNlcModals();
	const guardFunction = useIsRegistrationCompleteGuard();

	onMounted(() => {
		guardFunction({
			success: () => {
				open("LazyOModalRegistrationComplete");
			}
		});
	});
};

export default useInitRegisterComplete;
